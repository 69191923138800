import { useQuery } from '@tanstack/react-query'
import { createContext, useEffect } from 'react'

import { fetchWithTimeout } from '@utils/fetchWithTimeout'
import { getAppConfig } from '@utils/getAppConfig'

import { ConfigCatRequest, FeatureFlag } from '@appTypes/PagesApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'
import { useBasin } from '@hooks/useBasin'
import useSetState from '@hooks/useSetState'

import { FEATURE_FLAGS } from '@config/featureFlags'

interface FeatureFlagContext {
    featureFlags: FeatureFlag
    isLoading: boolean
    setFeatureFlags: (featureFlags: Partial<FeatureFlag>) => void
}

export const FeatureFlagContext = createContext<FeatureFlagContext>({
    featureFlags: FEATURE_FLAGS,
    setFeatureFlags: () => undefined,
    isLoading: false,
})

export const FeatureFlagProvider = ({
    children,
    initialData,
}: {
    children: React.ReactNode
    initialData?: FeatureFlag
}) => {
    const appConfig = getAppConfig()

    const { appQueryState } = useAppQueryState()
    const { updateMetadata } = useBasin()

    const [localFeatureFlags, setFeatureFlags] = useSetState({
        ...initialData,
    })

    const isRequestEnabled =
        appQueryState.customerId != null && process.env.NODE_ENV !== 'test'

    const { data, isSuccess, isLoading } = useQuery(
        ['configcat', appQueryState.customerId],
        () => {
            const body: ConfigCatRequest = {
                keys: Object.keys(FEATURE_FLAGS),
                user: {
                    identifier: appQueryState.customerId!,
                    custom: {
                        whitelabel: appConfig.NAME_WHITELABEL,
                        campaign: appQueryState.campaign!,
                    },
                },
            }

            return fetchWithTimeout(
                `${process.env.NEXT_PUBLIC_BASE_PATH}/api/configcat/`,
                {
                    timeoutMs: 5000,
                    body: JSON.stringify(body),
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                }
            ).then(res => res.json())
        },
        {
            staleTime: Infinity,
            enabled: isRequestEnabled,
            retry: 2,
            refetchOnWindowFocus: false,
        }
    )

    function updateBasinWithExperiments(
        configCatResponse: Record<string, any>
    ) {
        const basinExperiments = []

        for (const [key, value] of Object.entries(configCatResponse)) {
            if (key.startsWith('experiment_')) {
                basinExperiments.push({
                    experiment: key,
                    variation: `${key}.${value}`,
                })
            }
        }

        if (basinExperiments.length > 0) {
            updateMetadata({
                variation: basinExperiments.map(v => v.variation).join(','),
                variations: basinExperiments,
            })
        }
    }

    useEffect(() => {
        if (isSuccess && data != null) {
            updateBasinWithExperiments(data)
        }
    }, [isSuccess])

    const featureFlags = (() => {
        const definedFeatureFlags = { ...FEATURE_FLAGS, ...localFeatureFlags }

        if (data == null || !isSuccess) {
            return definedFeatureFlags
        }

        for (const [key, value] of Object.entries(data)) {
            if (key in definedFeatureFlags) {
                ;(definedFeatureFlags as any)[key] = value
            }
        }

        return { ...definedFeatureFlags, ...localFeatureFlags }
    })()

    return (
        <FeatureFlagContext.Provider
            value={{
                isLoading: isLoading && isRequestEnabled,
                featureFlags,
                setFeatureFlags,
            }}
        >
            {children}
        </FeatureFlagContext.Provider>
    )
}
