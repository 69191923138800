import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { getAllowedQueryParametersFromQuery } from '@utils/getAllowedQueryParametersFromQuery'
import {
    SupportedQueryParameters,
    getAppStateFromQueryParameters,
} from '@utils/getAppStateFromQueryParameters'
import { getPathnameFromAsPath } from '@utils/getPathnameFromAsPath'
import { getQueryParametersFromAppState } from '@utils/getQueryParametersFromAppState'

import { CategoryPath } from '@config/categories'

import useRouterParams from './useRouterParams'

/**
 * These are the routes available on the application. This
 * gives us type-safety when navigating to another route.
 */
type AllowedRoutes =
    | 'alternative-products'
    | CategoryPath
    | ['card', string]
    | [CategoryPath, 'card', string]
    | ''

/**
 * This serves as the way of retrieving state from the URL as well
 * as updating the URL to preserve state across pages. Storing state
 * in the URL means we can preserve the customer's journey.
 */
const useAppQueryState = () => {
    /**
     * This fixes an issue with NextJS unable to
     * get router params if there is a trailing
     * slash in the url.
     */
    const routerParams = useRouterParams()

    const router = useRouter()

    /**
     * The app state from the query parameters, we pass
     * the query parameters as well as the router parameters.
     */
    const appQueryState = useMemo(() => {
        return getAppStateFromQueryParameters({
            ...router.query,
            ...routerParams,
        })
    }, [router.query, routerParams])

    /**
     * This returns the valid pathname for a given route as well
     * as the query parameters that we want to persist across
     * the application.
     *
     * @param route - The allowed route to navigate to
     * @returns The pathname and the query parameters
     */
    const getRouteWithAppState = (route: AllowedRoutes) => {
        const queryParameters = {
            ...getQueryParametersFromAppState(appQueryState),
            ...getAllowedQueryParametersFromQuery(router.query),
        }

        return {
            pathname:
                typeof route === 'string'
                    ? route === ''
                        ? '/'
                        : `/${route}/`
                    : `/${route.join('/')}/`,
            query: queryParameters,
        }
    }

    /**
     * This returns the fully qualified query string
     * from the query state.
     *
     * @returns The query string from the app state
     */
    const getQueryStringWithAppState = () => {
        const queryParameters = {
            ...getQueryParametersFromAppState(appQueryState),
            ...getAllowedQueryParametersFromQuery(router.query),
        }

        return Object.keys(queryParameters)
            .filter(parameterKey => (queryParameters as any)[parameterKey])
            .map(
                parameterKey =>
                    `${parameterKey}=${(queryParameters as any)[parameterKey]}`
            )
            .join('&')
    }

    /**
     * This returns the fully qualified url given the
     * route as well as the query string from the app
     * state.
     *
     * @param route - The allowed route to navigate to
     * @returns The route url
     */
    const getRouteUrl = (route: AllowedRoutes) => {
        const routeWithAppState = getRouteWithAppState(route)
        return `${process.env.NEXT_PUBLIC_BASE_PATH}${
            routeWithAppState.pathname
        }?${getQueryStringWithAppState()}`
    }

    /**
     * Navigate to the allowed route with the app state.
     *
     * @param route - The allowed route to navigate to
     * @param options - Additional options to pass through to
     * the push function
     */
    const goToRouteWithAppState = (
        route: AllowedRoutes,
        options?: {
            hash?: string
            queryParameters?: SupportedQueryParameters
            shallow?: boolean
        }
    ) => {
        const routeWithAppState = getRouteWithAppState(route)

        router.push(
            {
                pathname: routeWithAppState.pathname,
                query: {
                    ...routeWithAppState.query,
                    ...options?.queryParameters,
                },
                hash: options?.hash,
            },
            undefined,
            options
        )
    }

    /**
     * Update the app query state as well as the URL with
     * valid query parameters.
     *
     * @param queryParameters - The supported query parameters
     * @param options - Additional options to pass through to
     * the push function
     */
    const updateAppQueryState = (
        queryParameters: SupportedQueryParameters,
        options?: { hash?: string; shallow?: boolean }
    ) => {
        router.replace(
            {
                pathname: getPathnameFromAsPath(router.asPath),
                query: {
                    ...getQueryParametersFromAppState(appQueryState),
                    ...queryParameters,
                    ...getAllowedQueryParametersFromQuery(router.query),
                },
                hash: options?.hash,
            },
            undefined,
            { shallow: options?.shallow ?? true }
        )
    }

    return {
        isReady: router.isReady,
        appQueryState,
        goToRouteWithAppState,
        getRouteWithAppState,
        updateAppQueryState,

        getRouteUrl,
        getQueryStringWithAppState,
    }
}

export default useAppQueryState
