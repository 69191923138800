import { useCallback } from 'react'

import { getAppConfig } from '@utils/getAppConfig'
import { getMarketingTrackingData } from '@utils/getMarketingTrackingData'

import {
    CreditCardProductCategory,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'

import { getIsDownsellCreditCard } from '@routes/ScoredTableRoute/components/ScoredTable/utils/getDownsellCreditCards'

import { KbaScenario } from '@config/kbaScenarioKeys'

import useAppQueryState from './useAppQueryState'
import { useBasin } from './useBasin'
import useFeatureFlags from './useFeatureFlags'
import useGoogleTagManager from './useGoogleTagManager'
import { getVariationsFromFeatureFlags } from './utils/getVariationsFromFeatureFlags'
import { BasinEvents } from '@appTypes/BasinEvents.interface'

type AppPromotionTypes = 'creditCardsTable' | 'improveEligibilityPromotion'

const useLogEvents = () => {
    const appConfig = getAppConfig()
    const { send } = useBasin()
    const { appQueryState } = useAppQueryState()
    const gtmSend = useGoogleTagManager()
    const { featureFlags } = useFeatureFlags()

    const trackingData = getMarketingTrackingData(appQueryState)

    const whitelabel = appConfig.NAME_WHITELABEL

    const logVisitEvent = useCallback(() => {
        const variations = getVariationsFromFeatureFlags(featureFlags)

        const eventData = {
            referrer: document.referrer,
            url: document.URL,
            useragent: navigator.userAgent,
            client: whitelabel,
            partnerName: whitelabel,

            customerId: appQueryState.customerId,
            customerType: appQueryState.customerType,
            isKnownCustomer: appQueryState.isKnownCustomer,
            adgroup: appQueryState.adgroup,
            visitOrigin: appQueryState.visitOrigin,

            sendId: trackingData.sendId,
            mediasource: trackingData.m,
            campaign: trackingData.cam,
            keyword: trackingData.k,
            googleClickId: trackingData.gclid,
            variation: variations.join(','),
        }

        send('Visit', eventData)
    }, [send, appQueryState, trackingData, whitelabel])

    const logPageViewEvent = useCallback(
        ({ category }: { category: string }) => {
            const eventData = {
                category,
                visitOrigin: appQueryState.visitOrigin,
                url: document.URL,
                partnername: whitelabel,
                client: 'web',
                customerId: appQueryState.customerId,
                scoreId: appQueryState.scoreId,
            }
            send('PageView', eventData)
        },
        [send, appQueryState]
    )

    const logCheckEligibilityEvent = useCallback(
        ({
            trigger,
            category,
            productCode,
            rowNumber,
        }: {
            category: string
            productCode?: string
            rowNumber?: number
            trigger: string
        }) => {
            send('FormOpen', {
                trigger,
                category,
                productCode,
                row: rowNumber,
            })
        },
        [send]
    )

    const logNavigateToCardPageEvent = useCallback(
        ({
            rowNumber,
            productCode,
            trigger,
            isNewOffer,
        }: {
            isNewOffer: boolean
            productCode: string
            rowNumber: number
            trigger: string
        }) => {
            gtmSend({
                event: 'show_details',
                trigger,
            })

            send('RowExpand', {
                row: rowNumber,
                productcode: productCode,
                isNewOffer,
            })
        },
        [send]
    )

    const logCardApplyEvent = useCallback(
        ({
            productCode,
            score,
            trigger,
            clickSource,
            rowNumber,
            status,
            category,
            isBestMatchCard,
            isCounterOffer,
            isNewOffer,
        }: {
            category: CreditCardProductCategory
            clickSource: string
            isBestMatchCard: boolean
            isCounterOffer: boolean
            isNewOffer: boolean
            productCode: string
            rowNumber: number
            score: number
            status: string
            trigger: string
        }) => {
            gtmSend({
                event: 'cards-click-out',
                category,
                hasPassword: false,
            })

            send('PostClick', {
                customerId: appQueryState.customerId,
                customerType: appQueryState.customerType,
                isKnownCustomer: appQueryState.isKnownCustomer,
                visitOrigin: appQueryState.visitOrigin,
                score,
                status,
                isBestMatchCard,
                isCounterOffer,
                category,
                row: rowNumber,
                clicksource: clickSource,
                productcode: productCode,
                trigger,
                client: 'web',
                isDownsell: appQueryState.referrer === 'loansdownsell',
                googleClickId: trackingData.gclid,
                isNewOffer,
            })
        },
        [
            appQueryState.customerId,
            appQueryState.customerType,
            appQueryState.isKnownCustomer,
            appQueryState.referrer,
            appQueryState.visitOrigin,
            gtmSend,
            send,
        ]
    )

    const logAlternativeProductApplyEvent = useCallback(
        ({
            productCode,
            rowNumber,
            trigger,
            clickSource,
        }: {
            clickSource: string
            productCode: string
            rowNumber: number
            trigger: string
        }) => {
            send('PostClick', {
                productCode,
                row: rowNumber,
                score: 1,
                trigger,
                clicksource: clickSource,
                client: 'web',
                status: '',
                category: 'AP',
                isBestMatchCard: false,
                googleClickId: trackingData.gclid,
            })
        },
        [send]
    )

    const logSortChangeEvent = useCallback(
        ({ sortBy, category }: { category: string; sortBy: string }) => {
            send('Sort', {
                sortby: sortBy,
                category,
            })
        },
        [send]
    )

    const logCategoryChangeEvent = useCallback(
        ({ category }: { category: string }) => {
            send('CategoryChange', {
                category,
            })
        },
        [send]
    )

    const logAlternativeProductCategoryChangeEvent = useCallback(
        ({ categoryName }: { categoryName: string }) => {
            send('AlternativeProductCategoryClick', {
                customerId: appQueryState.customerId,
                categoryName,
            })
        },
        [appQueryState.customerId, send]
    )

    const logBannerKbaDisplayEvent = useCallback(
        ({ scenario }: { scenario: KbaScenario }) => {
            send('BannerKbaDisplay', {
                customerId: appQueryState.customerId,
                url: document.URL,
                scenario,
            })
        },
        [appQueryState.customerId, send]
    )

    const logBannerKbaClickEvent = useCallback(
        ({ scenario }: { scenario: KbaScenario }) => {
            send('BannerKbaClick', {
                customerId: appQueryState.customerId,
                scenario,
            })
        },
        [appQueryState.customerId, send]
    )

    const logEligibilityFactorsCardShownEvent = useCallback(
        ({
            numberOfRedEligibilityFactors,
            isOnBoardingRequired,
        }: {
            isOnBoardingRequired: boolean
            numberOfRedEligibilityFactors: number
        }) => {
            send('EligibilityFactorsCardShown', {
                customerId: appQueryState.customerId,
                redEligibilityFactorsCount: numberOfRedEligibilityFactors,
                type: isOnBoardingRequired ? 'T20' : 'T30',
            })
        },
        [send]
    )

    const logEligibilityFactorsCardDeepLinkClickEvent = useCallback(
        ({
            numberOfRedEligibilityFactors,
            route,
        }: {
            numberOfRedEligibilityFactors: number
            route: string
        }) => {
            send('EligibilityFactorsDeepLinkClick', {
                customerId: appQueryState.customerId,
                redEligibilityFactorsCount: numberOfRedEligibilityFactors,
                route,
            })
        },
        [send]
    )

    const logEligibilityFactorsCardT20UpgradeLinkClickEvent = useCallback(
        ({ route }: { route: string }) => {
            send('EligibilityFactorsCardT20UpgradeLinkClick', {
                customerId: appQueryState.customerId,
                route,
            })
        },
        [send]
    )

    const logScoredTableUpdateEvent = useCallback(
        ({
            cards,
            minCreditLimit,
            category,
            sortedBy,
            seenNewOffers,
            numberOfNewOffersSeen,
        }: {
            cards: ScoredCreditCardProduct[] | null
            category: CreditCardProductCategory
            minCreditLimit?: number
            numberOfNewOffersSeen: number
            seenNewOffers: boolean
            sortedBy: string
        }) => {
            if (cards == null) return
            const categoryCards = cards.map((card: ScoredCreditCardProduct) => {
                return {
                    productCode: card.productCode,
                    isSponsored: card.sponsored,
                    isDownsell: getIsDownsellCreditCard(card),
                    guaranteedCreditLimit:
                        card.guaranteedRate?.guaranteedCreditLimit,
                    guaranteedOfferType:
                        card.guaranteedRate?.guaranteedOfferType,
                    isCounterOffer: card.guaranteedRate?.isCounterOffer,
                }
            })
            send('ScoredTableUpdate', {
                customerId: appQueryState.customerId,
                sortedProducts: categoryCards,
                minCreditLimit,
                category,
                sortedBy,
                seenNewOffers,
                numberOfNewOffersSeen,
            })
        },
        [send]
    )

    const logAppPromotionView = useCallback(
        ({ promotion }: { promotion: AppPromotionTypes }) => {
            send('AppPromotionView', {
                promotion,
                source: 'cardsProductTable',
                platform: navigator.userAgent,
            })
        },
        [send]
    )

    const logAppPromotionClick = useCallback(
        ({
            promotion,
            source,
        }: {
            promotion: AppPromotionTypes
            source:
                | 'cardsProductTable'
                | 'creditReport'
                | 'dismiss'
                | 'getTheApp'
                | 'modal'
        }) => {
            send('AppPromotionClick', {
                promotion,
                source,
                platform: navigator.userAgent,
            })
        },
        [send]
    )

    const logFeatureCardsViewEvent = useCallback(
        ({
            numberOfFeaturedCards,
            category,
        }: {
            category: CreditCardProductCategory
            numberOfFeaturedCards: number
        }) => {
            send('FeatureCards', {
                numberOfFeaturedCards,
                category,
            })
        },
        [send]
    )

    const logCreditLimitIntroView = useCallback(() => {
        send('CreditLimitIntroView', {
            customerId: appQueryState.customerId,
            client: 'web',
        })
    }, [send])

    const logMinCreditLimitSubmit = useCallback(
        ({
            minCreditLimit,
            source,
        }: {
            minCreditLimit: number
            source:
                | 'GuaranteedCreditLimitIntro'
                | 'creditCardsMinCreditLimitUpdateModal'
        }) => {
            send('MinCreditLimitSubmit', {
                customerId: appQueryState.customerId,
                minCreditLimit,
                source,
                client: 'web',
            })
        },
        [send]
    )

    const logMinCreditLimitSkip = useCallback(
        ({ source }: { source: 'GuaranteedCreditLimitIntro' }) => {
            send('MinCreditLimitSkip', {
                customerId: appQueryState.customerId,
                source,
                client: 'web',
            })
        },
        [send]
    )

    const logGuaranteedCreditLimitLanding = useCallback(() => {
        send('GuaranteedCreditLimitLanding', {
            customerId: appQueryState.customerId,
            scoreId: appQueryState.scoreId,
        })
    }, [send])

    const logClickOnNewOffersBanner = useCallback(
        ({ productCode }: BasinEvents['NewOffersBannerClick']) => {
            send('NewOffersBannerClick', { productCode })
        },
        [send]
    )

    return {
        logVisitEvent,
        logPageViewEvent,
        logCheckEligibilityEvent,
        logNavigateToCardPageEvent,
        logCardApplyEvent,
        logAlternativeProductApplyEvent,
        logCategoryChangeEvent,
        logSortChangeEvent,
        logAlternativeProductCategoryChangeEvent,
        logFeatureCardsViewEvent,
        logScoredTableUpdateEvent,

        logBannerKbaDisplayEvent,
        logBannerKbaClickEvent,

        logEligibilityFactorsCardShownEvent,
        logEligibilityFactorsCardDeepLinkClickEvent,
        logEligibilityFactorsCardT20UpgradeLinkClickEvent,

        logAppPromotionClick,
        logAppPromotionView,

        logCreditLimitIntroView,
        logMinCreditLimitSubmit,
        logMinCreditLimitSkip,
        logGuaranteedCreditLimitLanding,

        logClickOnNewOffersBanner,
    }
}

export default useLogEvents
